<template>
  <v-dialog v-model="visible" @click:outside="close" persistent content-class="bg--white" width="90%">
    <reservation-calendar
      :client-or-member-id="id"
      @close="close"
      @next="next"
      :dataCalendar="dataCalendar"
    />
  </v-dialog>
</template>

<script>
import ReservationCalendar from './ReservationCalendar'
export default {
  name: 'ReservationCalendarPageDialog',
  components: { ReservationCalendar },
  props: {
    visible: Boolean,
    dataCalendar: Object
  },
  computed: {
    dialog: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.close()
        }
      }
    },
    id () {
      return this.$route.query.clientId
    }
  },

  methods: {
    close () {
      this.$emit('resetCount')
      this.$emit('close')
    },
    next (payload) {
      // the payload has the following props
      // clientId: Int!
      // roomTypeId: Int!
      // contractId: Int
      // bookingTypeId: Int
      // checkInDate: DateString!
      // checkOutDate: DateString!
      // days: {stayDate: DateString!; inventoryTypeId: Int!}[]
      // bookingPlanId: Int

      if (JSON.parse(localStorage.getItem('statusSetCalenDar')) || JSON.parse(localStorage.getItem('statusAddGroup'))) {
        this.$emit('updated')
        this.$emit('close')
      } else {
        this.$router.push('/booking/new-booking')
      }
      // console.log(121212, payload)
    }
  }
}
</script>
