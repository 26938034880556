<template>
  <div v-if="canUseTvp" class="d-flex justify-space-around ml-2">
    <div class="pre-pay-item">
      <div class="text-small">所有ポイント数</div>
      <div class="pre-pay-value">{{ currentTvp | toThousands }} Pt</div>
    </div>
    <div class="pre-pay-item">
      <div class="text-small"> </div>
      <div class="d-flex pre-pay-value">
        <v-btn color="var(--bt__red)" outlined @click="dialog = true ">ポイント数利用・変更</v-btn>
      </div>
    </div>
    <div class="pre-pay-item">
      <div class="text-small">ポイント利用数</div>
      <div class="pre-pay-value color-negative">-{{ value | toThousands }} Pt</div>
    </div>
    <div class="pre-pay-item">
      <div class="text-small">現地支払金額</div>
      <div class="pre-pay-value color-positive">¥{{ (totalAccommodationFee - value) | toThousands }}</div>
    </div>
    <PrePaymentChangeDialog
      :visible="dialog"
      :currentTvpPlusRegisteredAmount="currentTvp"
      :maxUsePoint="maxUsePoint"
      :targetChargesTotal="totalAccommodationFee"
      :currentPrePayment="value"
      @close="dialog = false"
      @changed="confirmTvp"
    />
  </div>
</template>

<script>
import { canUseTvpForNewBooking } from '@/constants/productTypeId'
import gql from 'graphql-tag'
import PrePaymentChangeDialog from '../BillingStatement/prePaymentChangeDialog.vue'

export default {
  components: { PrePaymentChangeDialog },
  props: {
    clientId: Number,
    productTypeId: Number,
    totalAccommodationFee: Number,
    value: Number
  },
  data () {
    return {
      availablePoints: null,
      dialog: false
    }
  },
  computed: {
    canUseTvp () {
      return this.clientId && canUseTvpForNewBooking(this.productTypeId)
    },
    currentTvp () {
      return this.availablePoints?.tvpPoint ?? 0
    },
    maxUsePoint () {
      return Math.min(this.currentTvp, this.totalAccommodationFee)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (v) {
        this.localPoint = v ?? 0
        this.$nextTick(() => this.syncInput())
      }
    }
  },
  methods: {
    syncInput () {
      this.$emit('input', this.localPoint)
    },
    reset () {
      this.localPoint = this.value ?? 0
      this.syncInput()
    },
    confirmTvp (newValue) {
      this.$emit('input', newValue)
    }
  },
  apollo: {
    availablePoints: {
      query: gql`query availablePoints($clientId: Int!) {
        availablePoints(clientId: $clientId) {
          tvpPoint
        }
      }`,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          clientId: this.clientId
        }
      },
      skip () {
        return !this.canUseTvp
      },
      update (data) {
        return data.availablePoints
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pre-pay-item {
  margin-left: 5px;
  margin-right: 5px;

  .text-small {
    color: #000000 !important;
    font-weight: 600 !important;
  }

  .pre-pay-value {
    min-height: 30px;
    line-height: 30px;
  }
}
</style>
