<template>
  <div class="px-main --dark py-4">
    <v-row>
      <v-col :cols="6">
        <h1 class="page-title-main" v-if="reversion">
          {{ $t("modalReservation.title") }}
          {{ reversion.name }} 顧客ID{{ reversion.id }} 会員番号{{(reversion.memberId ? ' ' + reversion.memberId : ' 無し')}}
        </h1>
      </v-col>
      <v-col :cols="6" class="d-flex justify-end">
        <v-btn
          color="var(--bt__red)"
          class="mr-3 d-flex align-center btn_status white--text"
          href="javascript:window.open('','_self').close();"
        >
          <v-icon class="white--text"> mdi-close </v-icon>
          {{ $t("common.close") }}
        </v-btn>
        <v-btn
          class="white--text"
          color="var(--bt__blue)"
          :disabled="!checkUser"
          @click="$withDelay(createBooking)"
        >
          {{ $t("modalReservation.title") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card class="mt-5">
      <v-row>
        <v-col :cols="6" class="mt-5">
          <v-card
            class="ml-8 mx-4 px-10 py-5"
            color="var(--bg__secondary)"
            elevation="5"
          >
            <div class="d-flex">
              <v-btn
                @click="setCalendar()"
                class="white--text mt-3"
                color="var(--bt__blue)"
                :disabled="!checkUser"
              >
                {{ $t("contractDetail.addFromCalendar") }}
              </v-btn>
              <v-checkbox
                  v-model="priorityBooking"
                  label="優先予約"
                  class="ml-6 checkbox-priority-booking"
                  hide-details
              ></v-checkbox>
            </div>
            <v-col :cols="6" class="mt-3 pl-0 pb-0">
              <span class="text-small">{{
                $t("contractDetail.usageContract")
              }}</span>
              <h5 class="mt-3">{{ usageContract }}</h5>
            </v-col>
            <div class="mt-5 d-flex" style="margin-left: -11px !important">
              <v-col :cols="6">
                <div>
                  <span class="text-small">{{
                    $t("contractDetail.selectFacility")
                  }}</span>
                  <br />
                  <h5 class="mt-3">{{ selectFacility }}</h5>
                </div>
              </v-col>

              <v-col :cols="6">
                <div>
                  <span class="text-small">{{
                    $t("common.roomType")
                  }}</span>
                  <br />
                  <h5 class="mt-3">{{ roomType }}</h5>
                </div>
              </v-col>
            </div>

            <!--            date time                -->
            <div class="mt-3 d-flex ml-4 textdate">
              <v-col :cols="3">
                <div>
                  <span class="text-small">{{ $t("common.checkIn") }}</span>
                </div>
                <div>
                  <v-icon class="ml-n8 mr-2">mdi-calendar-month</v-icon>

                  <span>
                    {{ formatDate(checkInDate) }}
                  </span>
                </div>
              </v-col>
              <v-col :cols="1" class="d-flex align-end mb-1"> ~ </v-col>
              <v-col :cols="3">
                <div>
                  <span class="text-small">{{ $t("common.checkOut") }}</span>
                </div>
                <div>
                  <v-icon class="ml-n8 mr-2">mdi-calendar-month</v-icon>

                  <span>
                    {{ formatDate(checkOutDate) }}
                  </span>
                </div>
              </v-col>
              <v-col :cols="3" class="d-flex align-end pt-0">
                <span class="text-size-normal">
                  {{ numNight }}{{ $t("contractDetail.night") }}</span
                >
              </v-col>
            </div>
            <!--            select                -->
            <div class="mt-4 d-flex">
              <v-row>
                <v-col :cols="4">
                  <span class="text-small">{{
                    $t("common.adult")
                  }}</span>
                  <h5>{{ numberOfAdults }}</h5>
                </v-col>
                <v-col :cols="4">
                  <span class="text-small">{{
                    $t("contractDetail.nonSleepChild")
                  }}</span>
                  <h5>{{ nonSleepChild }}</h5>
                </v-col>
                <v-col :cols="4">
                  <span class="text-small ">{{
                    $t("contractDetail.sleepChild")
                  }}</span>
                  <h5>{{ sleepChild }}</h5>
                </v-col>
              </v-row>
            </div>
            <div class="mt-5 d-flex align-baseline">
              <div>
                <span class="text-small">合計料金</span>
                <br />
                <h5 class="color-positive" style="color: #2a7edc" v-if="totalPrice"
                  >{{totalPrice.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })}}</h5
                >
              </div>
              <div class="ml-16" v-if="isPointBasedProduct">
                <span class="text-small">合計ポイント</span>
                <br />
                <h5 class="color-positive" style="color: #2a7edc">{{totalPoint}}pt</h5>
              </div>

              <div>
                <BookingPrePaymentInput
                  :clientId="clientId"
                  :productTypeId="productTypeId"
                  :totalAccommodationFee="totalPrice"
                  v-model="prePaymentAmount"
                />
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="6" class="px-10 py-7">
          <v-form ref="formInfo">
            <div class="mx-4 pa-0">
            <div class="mt-5">
              <v-select
                :disabled="!checkUser"
                @change="getSubMember($event)"
                :items="listSubMemberToShow"
                label="予約作成会員"
                item-text="nameWithType"
                item-value="id"
                dense
                class="text-title"
              ></v-select>
            </div>
            <v-col :cols="5" class="pa-0">
              <div>
                <v-text-field
                  :disabled="!checkUser"
                  @change="getChangeBookingBarNote($event)"
                  v-model="bookingBarNote"
                  :label="$t('contractDetail.remarkNote')"
                  class="text-title"
                >
                </v-text-field>
              </div>
            </v-col>
            <div class="mt-5">
              <v-combobox
                :disabled="!checkUser"
                @change="getRepresentativeName($event)"
                :items="listSubMemberToShow"
                item-text="nameWithType"
                item-value="id"
                label="宿泊代表者"
                dense
                class="text-title"
              ></v-combobox>
            </div>
            <div class="mt-5">
              <v-text-field
                :rules="[$rules.checkKatakana, $rules.checkLenghInput(255)]"
                v-model="representativeKana"
                label="宿泊代表者ヨミガナ"
                class="text-title pt-0"
              >
              </v-text-field>
            </div>
            <div class="mt-5">
              <!-- <v-text-field
                :rules="[$rules.checkNumber, $rules.checkLenghInput(15)]"
                v-model="representativeTel"
                label="電話番号"
                class="text-title pt-0"
              >
              </v-text-field> -->
              <v-text-field
                v-model="representativeTel"
                label="電話番号"
                class="text-title pt-0"
              >
              </v-text-field>
            </div>
            <div class="mt-5">
              <v-text-field
                :rules="[$rules.isEmailValid]"
                v-model="representativeEmail"
                label="メールアドレス"
                class="text-title pt-0"
              >
              </v-text-field>
            </div>
            <div class="mt-5">
              <v-text-field
                :rules="[$rules.checkLenghInput(255)]"
                v-model="representativeAddress1"
                label="住所"
                class="text-title pt-0"
              >
              </v-text-field>
            </div>
          </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row class="mt-5 pa-10 d-flex align-center">
        <v-col :cols="6">
          <h3>{{ $t("contractDetail.parkingPlace") }}</h3>
        </v-col>
        <v-col :cols="6" class="d-flex align-center justify-end">
          <v-btn class="mt-2 btn-add-parking" :disabled="!checkUser" @click="addNewItem()">
            <v-icon> mdi-plus </v-icon>
            {{ $t("contractDetail.addParkingReservation") }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="px-10 pb-10">
        <template>
          <thead>
            <tr>
              <th style="width: 300px">期間</th>
              <th style="width: 250px">駐車場</th>
              <th style="width: 170px">車高</th>
              <th style="width: 200px">ナンバー</th>
              <th style="width: 100px">カラー</th>
              <th style="width: 200px">ステータス</th>
            </tr>
          </thead>
        </template>
        <template>
          <v-form ref="form">
            <tbody>
              <tr v-for="item in listItemParking" :key="item.id">
                <td style="width: 300px">
                  <div class="ml-4 d-flex align-center">
                    <v-menu
                      :ref="'menu' + item.id"
                      :value="item.showDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex align-center">
                          <v-icon
                            class="ml-n8 mr-2"
                            style="
                              color: #616161 !important;
                              font-size: 30px !important;
                            "
                            >mdi-calendar-month</v-icon
                          >
                          <v-text-field
                            :value="formatDateRange(item.dates)"
                            :rules="rule.datePicker"
                            @click.stop="item.showDate = true"
                            v-bind="attrs"
                            v-on="on"
                            readonly
                            dense
                            outlined
                            class="mt-5 custom-input"
                          ></v-text-field>
                        </div>
                      </template>
                      <v-date-picker
                        @input="getDataChangeDate(item, $event)"
                        :min="checkInDate"
                        :rules="rule.datePicker"
                        no-title
                        scrollable
                        v-model="item.dates"
                        range
                        :first-day-of-week="0"
                        :locale="$i18n.locale"
                        class="v-date-picker-custom"
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </div>
                </td>
                <td class="align-center justify-end">
                  <v-select
                    style="max-width: 200px"
                    @change="
                      item.status = true;
                      getChangePakingId();
                    "
                    v-model="item.parkingId"
                    dense
                    :rules="rule.parkingName"
                    outlined
                    item-text="name"
                    item-value="id"
                    :items="facilityParkingList"
                    class="text-size-normal mx-5 mt-5"
                  >
                  </v-select>
                </td>
                <td>
                  <v-text-field
                    :disabled="!item.status"
                    class="text--black text--small"
                    outlined
                    style="width: 180px; height: 44px !important"
                    :rules="[
                      $rules.checkNumber,
                      (v) =>
                        validateHeight(item.parkingId, v) || '車高制限を超えています。',
                    ]"
                    v-model="item.height"
                    type="number"
                    @change="getChangeHeight($event, item)"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    outlined
                    class="text--black text--small"
                    style="width: 140px; margin-left: 30px; height: 44px !important"
                    v-model="item.carNumber"
                    @change="getChangeCarNumber($event, item)"
                  ></v-text-field>
                </td>
                <td>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        dark
                        v-on="on"
                        style="max-width: 200px; margin-left: 50px"
                        :color="item.color"
                      />
                    </template>
                    <car-color-picker
                      @input="getDataColorPicker($event, item)"
                      v-model="item.color"
                      class="mx-auto"
                    />
                  </v-menu>
                </td>
                <td>
                 <v-btn style="width: 150px; margin-left: 50px; color: #FF0000; background-color: #ffffff" @click="deleteParking(item)">削除</v-btn>
                </td>
              </tr>
            </tbody>
          </v-form>
        </template>
      </div>
    </v-card>

    <ReservationCalendarPageDialog :visible="backCalendar" @close="actionCloseDialog" :dataCalendar="dataCalendar"/>
  </div>
</template>

<script>
import { GET_CLIENT_BY_ID_OR_MEMBER_ID } from '@/api/graphql/guestRoomDailyReport/reservation-list'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { CLIENT_MEMBER_INFO } from '@/api/graphql/bookingDetail/booking-guest'
import { FACILITY_PARKING_LIST } from '@/api/graphql/facility-item-parking'
import { BOOKING_TYPES_LIST } from '@/api/graphql/bookingDetail/booking-detail-basic'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import ReservationCalendarPageDialog from '../reservation/ReservationCalendarPageDialog.vue'
import gql from 'graphql-tag'
import { isEmailValid, checkKatakana, checkNumber } from '@/utils/validators.js'
import CarColorPicker from '../../../components/Input/CarColorPicker.vue'
import { getAddressString } from '@/utils/string'
import BookingPrePaymentInput from '@/components/Input/BookingPrePaymentInput'
import { isPointBasedProduct } from '@/constants/productTypeId'
import { isoDate } from '@/utils/dateUtil'

export default {
  name: 'NewBooking',
  data () {
    return {
      reservationId: 0,
      reversion: null,
      clientOrMemberId: '',
      dataCalendar: null,
      backCalendar: false,
      facilityId: null,
      totalPrice: null,
      totalPoint: null,
      dataCreateBooking: [],
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      // fake data
      bookingGroupId: null,
      bookingPlanId: null,
      force: false,
      contractId: 1,
      roomTypeId: 3,
      numberOfAdults: 1,
      numberOfChildren: 0,
      numberOfChildrenWithBedShare: 0,
      days: [
        {
          inventoryTypeId: 1
        }
      ],

      arrCreateBooking: [],

      createdClientId: null,
      createdSubMemberId: null,
      representativeClientId: null,
      representativeSubMemberId: null,
      checkInDate: '2021-11-09',
      checkOutDate: '2021-11-11',
      arrAddParking: [],
      color: '#fafafa',
      listItemParking: [],
      listParkingName: [],
      showDate: false,
      representativeTel: '',
      representativeEmail: null,
      representativeAddress1: '',
      representativeKana: '',
      representativeName: '',
      listSubMember: [],
      bookingBarNote: '',
      priorityBooking: false,
      showDialog: false,
      facilityParkingList: [],
      roomType: '禁煙',
      usageContract: '体験予約',
      selectFacility: '箱根強羅',
      numNight: 0,
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: new Date().toISOString().substr(0, 10),
      oldPerson: 3,
      sleepChild: 0,
      nonSleepChild: 0,
      country: '日本',
      parkingLotList: [],
      selectParkingLotList: ['機械式1', '機械式2', '機械式3'],
      rule: {
        datePicker: [
          (v) => this.checkdatePicker(v) || this.$t('rules.isRequired'),
          (v) => this.checkDuplicate(v) || this.$t('rules.isInvalid'),
          (v) => this.checkFormatdate(v) || this.$t('rules.isInvalid')
        ],
        parkingName: [(v) => !!v || this.$t('rules.isRequired')],
        height: [
          (v) => Number.isInteger(parseInt(v)) || this.$t('rules.isInvalid')
        ]
      },
      prePaymentAmount: null
    }
  },

  computed: {
    clientId () { return this.reversion?.id },
    listSubMemberToShow () {
      return this.listSubMember.map(row => {
        const data = {
          ...row
        }
        if (!data.name) data.name = '(お名前未設定)'

        let type = ''
        switch (row.subMemberTypeId) {
          case 1: type = '副会員'; break
          case 2: type = 'ファミリー会員'; break
          default: type = '会員'; break
        }
        data.nameWithType = `${data.name}(${type})`
        return data
      })
    },
    contract () {
      return this.reversion?.contracts.find(i => i.id === this.contractId)
    },
    productTypeId () {
      return this.contract?.productType.id
    },
    isPointBasedProduct () {
      return isPointBasedProduct(this.productTypeId)
    }
  },
  watch: {
    clientId: {
      immediate: true,
      handler () { this.getListClientMemberInfo() }
    }
  },
  components: {
    ReservationCalendarPageDialog,
    CarColorPicker,
    BookingPrePaymentInput
  },

  created () {
    localStorage.setItem('statusSetCalenDar', JSON.stringify(false))
  },

  mounted () {
    this.getClientByIdOrMemberId()
    this.getNightDay()
    this.getFacilityParkingList()
    this.getBookingTypeList()
  },
  methods: {
    ...mapMutations(['setCreateBooking', 'setBookingType']),
    ...mapActions(['createBookingV4']),
    checkKatakana,
    isEmailValid,
    checkNumber,
    actionCloseDialog () {
      localStorage.setItem('statusSetCalenDar', JSON.stringify(false))
      this.getData()
      this.backCalendar = false
    },

    async getClientByIdOrMemberId () {
      await this.$apollo.query({
        query: gql`${GET_CLIENT_BY_ID_OR_MEMBER_ID}`,
        variables: {
          id: this.$route.query.clientId
        }
      }).then(async (data) => {
        this.reversion = data.data.getClientByIdOrMemberId
        localStorage.setItem('statusAddGroup', JSON.stringify(false))
        this.getData()
      }).catch((error) => {
        console.error(error)
      })
    },

    async setCalendar () {
      const data = {
        roomTypeId: this.roomTypeId,
        contractId: this.contractId,
        numberOfAdults: this.numberOfAdults,
        numberOfChildren: this.nonSleepChild,
        numberOfBedShare: this.sleepChild,
        bookingPlanId: this.bookingPlanId,
        totalPrice: this.totalPrice,
        totalPoint: this.totalPoint,
        facilityId: this.facilityId,
        selectedDays: this.days,
        checkInDate: this.checkInDate,
        checkOutDate: this.checkOutDate,
        clientOrMemberId: this.clientOrMemberId
      }
      this.dataCalendar = data
      await localStorage.setItem('statusSetCalenDar', JSON.stringify(true))
      this.backCalendar = await true
      localStorage.setItem('statusAddGroup', JSON.stringify(true))
    },

    addNewItem () {
      const id = ++this.reservationId
      var fromDate = new Date(this.checkInDate).toISOString().substr(0, 10)
      var toDate = new Date(this.checkOutDate).toISOString().substr(0, 10)

      const data = {
        id: id + 1,
        status: false,
        parkingId: null,
        fromDate: fromDate,
        toDate: toDate,
        dates: [fromDate, toDate],
        height: null,
        carNumber: null,
        color: '#E0E0E0',
        maxHeight: null
      }

      this.listItemParking.push(data)
    },
    deleteParking (item) {
      if (item) {
        this.$swal.fire(
          {
            title: '削除しますか？',
            showCancelButton: true,
            confirmButtonText: '削除',
            confirmButtonColor: '#ff5252',
            cancelButtonText: '閉じる',
            cancelButtonColor: '#1976d2',
            reverseButtons: true
          }).then(async (result) => {
          if (result.isConfirmed) {
            this.removeItem(item)
          }
        })
      }
    },

    removeItem (itemRemove) {
      this.listItemParking = this.listItemParking.filter(item => item.id !== itemRemove.id)
    },

    createBooking () {
      if (this.$refs.form.validate() && this.$refs.formInfo.validate()) {
        var arrNew = []
        const arrUpdate = JSON.parse(JSON.stringify(this.listItemParking))
        for (let i = 0; i < arrUpdate.length; i++) {
          delete arrUpdate[i].dates
          delete arrUpdate[i].id
          delete arrUpdate[i].maxHeight
          delete arrUpdate[i].status
          delete arrUpdate[i].showDate
          arrNew.push(arrUpdate[i])
        }
        this.arrCreateBooking.parkings = arrNew
        this.arrCreateBooking.createdClientId = this.createdClientId
        this.arrCreateBooking.createdSubMemberId = this.createdSubMemberId
        this.arrCreateBooking.bookingBarNote = this.bookingBarNote
        this.arrCreateBooking.representativeName = this.representativeName
        this.arrCreateBooking.representativeClientId =
          this.representativeClientId
        this.arrCreateBooking.representativeSubMemberId =
          this.representativeSubMemberId
        this.arrCreateBooking.bookingGrrepresentativeSubMemberIdoupId = this.representativeSubMemberId
        this.arrCreateBooking.representativeKana = this.representativeKana
        this.arrCreateBooking.representativeTel = this.representativeTel
        this.arrCreateBooking.representativeEmail = this.representativeEmail
        this.arrCreateBooking.representativeAddress1 =
          this.representativeAddress1

        this.arrCreateBooking.bookingGroupId = this.bookingGroupId
        this.arrCreateBooking.bookingPlanId = this.bookingPlanId
        this.arrCreateBooking.force = false
        this.arrCreateBooking.contractId = this.contractId
        this.arrCreateBooking.roomTypeId = this.roomTypeId
        this.arrCreateBooking.checkInDate = this.checkInDate
        this.arrCreateBooking.numberOfAdults = this.numberOfAdults
        this.arrCreateBooking.numberOfChildren = this.nonSleepChild
        this.arrCreateBooking.numberOfChildrenWithBedShare =
          this.sleepChild
        this.arrCreateBooking.priorityBooking = this.priorityBooking
        this.arrCreateBooking.days = this.days
        this.arrCreateBooking.clientId = this.clientId
        this.arrCreateBooking.bookingPrePayment = this.prePaymentAmount ?? null

        const finalize = () => {
          this.setCreateBooking({ createBooking: this.arrCreateBooking })
          this.createBookingV4()
        }
        if (this.checkInDate < isoDate(new Date())) {
          // if checkInDate is in the past, show confirm dialog TO2020-937
          this.$confirm({
            message: '過去の日付で予約を作成します。よろしいですか？',
            ok: finalize
          })
        } else {
          finalize()
        }
      }
    },

    async getBookingTypeList () {
      await this.$apollo
        .query({
          query: gql`${BOOKING_TYPES_LIST}`
        })
        .then((data) => {
          const bookingTypesList = data.data.bookingTypesList
          this.setBookingType({ bookingTypesList: bookingTypesList })
        })
        .catch((error) => {
          console.error(error)
        })
    },

    checkdatePicker (date) {
      if (date !== null) {
        date = date.split(' ~ ')
        if (date.length < 2) {
          return false
        } else {
          return true
        }
      }
    },

    checkDuplicate (date) {
      date = date.split(' ~ ')
      if (date.length === 2) {
        if (date[0] === date[1]) {
          return false
        } else {
          return true
        }
      }
    },

    checkFormatdate (event) {
      const arrRule = event.split(' ~ ')
      if (arrRule.length === 2) {
        const date1 = new Date(arrRule[0])
        const date2 = new Date(arrRule[1])
        const date3 = date2.getTime() - date1.getTime()
        if (date3 > 0) {
          return true
        } else {
          return false
        }
      }
    },

    formatDateRange (date) {
      const dates = date.join(' ~ ')
      return dates.replace(/-/g, '/')
    },

    getSubMember (event) {
      if (event === 999999) {
        this.createdClientId = this.listSubMember[0].idClient
        this.createdSubMemberId = null
      } else {
        this.createdSubMemberId = event
        this.createdClientId = null
      }
    },

    getChangeBookingBarNote (event) {
      this.bookingBarNote = event
    },

    getRepresentativeName (event) {
      if (event.id) {
        const address = getAddressString(event)
        if (event.id === 999999) {
          this.representativeClientId = this.listSubMember[0].idClient
          this.representativeSubMemberId = null
          this.representativeName = event.name
          this.representativeKana = event.nameKana
          this.representativeTel = event.tel
          this.representativeEmail = event.email
          this.representativeAddress1 = address
        } else {
          this.representativeClientId = null
          this.representativeSubMemberId = event.id
          this.representativeName = event.name
          this.representativeKana = event.nameKana
          this.representativeTel = event.tel
          this.representativeEmail = event.email
          this.representativeAddress1 = address
        }
      } else {
        this.representativeName = event
        this.representativeClientId = null
        this.representativeSubMemberId = null
        this.representativeKana = ''
        this.representativeTel = ''
        this.representativeEmail = ''
        this.representativeAddress1 = ''
      }
    },

    validateHeight (parkingId, value) {
      if (!value) return true

      for (let i = 0; i < this.facilityParkingList.length; i++) {
        if (this.facilityParkingList[i].id === parkingId) {
          const maxHeight = this.facilityParkingList[i].maxHeight
          if (!maxHeight) {
            // there is no limit in height
            return true
          }
          return parseInt(value, 10) <= maxHeight
        }
      }
      return false
    },

    getChangeHeight (event, item) {
      let value = parseInt(event, 10)
      if (isNaN(value)) value = null
      item.height = value
    },

    getChangePakingId () {
      this.$refs.form.validate()
    },

    getDataChangeDate (item, event) {
      if (event.length === 2) {
        const arr = []
        const date1 = new Date(event[0])
        const date2 = new Date(event[1])
        const date3 = date2.getTime() - date1.getTime()
        if (date3 > 0) {
          arr.push(date1.toISOString().substr(0, 10))
          arr.push(date2.toISOString().substr(0, 10))
        } else {
          arr.push(date2.toISOString().substr(0, 10))
          arr.push(date1.toISOString().substr(0, 10))
        }
        item.fromDate = arr[0]
        item.toDate = arr[1]
      }
    },

    getDataColorPicker (event, item) {
    },

    async getListClientMemberInfo () {
      if (this.clientId) {
        await this.$apollo
          .query({
            query: gql`${CLIENT_MEMBER_INFO}`,
            variables: {
              id: this.clientId
            }
          })
          .then((data) => {
            this.listSubMember = data.data.clientMemberInfo.subMembers.slice()
            const datas = {
              ...data.data.clientMemberInfo.mainContact,
              id: 999999,
              idClient: data.data.clientMemberInfo.id,
              name: data.data.clientMemberInfo.name,
              nameKana: data.data.clientMemberInfo.nameKana
            }
            this.listSubMember.unshift(datas)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },

    async getFacilityParkingList () {
      await this.$apollo
        .query({
          query: gql`${FACILITY_PARKING_LIST}`,
          variables: {
            facilityId: JSON.parse(localStorage.getItem('facilityId')),
            hideQtyZero: true
          }
        })
        .then((data) => {
          this.facilityParkingList = data.data.facilityParkingList
          for (let i = 0; i < this.facilityParkingList.length; i++) {
            this.listParkingName.push(this.facilityParkingList[i].name)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    getData () {
      this.dataCreateBooking = JSON.parse(localStorage.getItem('dataCreateBooking'))
      this.bookingPlanId = this.dataCreateBooking.bookingPlanId
      this.contractId = this.dataCreateBooking.contractId
      this.roomTypeId = this.dataCreateBooking.roomTypeId
      this.numberOfAdults = this.dataCreateBooking.numberOfAdults
      this.nonSleepChild = this.dataCreateBooking.numberOfChildren
      this.sleepChild = this.dataCreateBooking.numberOfBedShare
      this.days = this.dataCreateBooking.days
      this.checkInDate = this.dataCreateBooking.checkInDate
      this.checkOutDate = this.dataCreateBooking.checkOutDate
      this.totalPrice = this.dataCreateBooking.totalPrice
      this.totalPoint = this.dataCreateBooking.totalPoint
      this.clientOrMemberId = this.dataCreateBooking.clientOrMemberId
      this.facilityId = JSON.parse(localStorage.getItem('facilityId'))
      this.getNightDay()
      this.getFacility()

      if (this.contract) {
        this.usageContract = this.contract.productType.name
        if (this.usageContract) {
          switch (this.usageContract) {
            case 'マイウィーク':
              this.usageContract = 'デイプラン'
              break
            case 'シェアリングポイント':
              this.usageContract = 'シェアリングポイント'
              break
            case 'フレックスポイント':
              this.usageContract = 'フレックスポイント'
              break
            case 'バケーションスタイル':
              this.usageContract = 'バケーションスタイル'
              break
            case 'バケーションマスター':
              this.usageContract = 'マスターズオプション'
              break
            case 'プライムウィーク':
              this.usageContract = ''
              break
          }
        }
      } else {
        this.usageContract = '体験予約'
      }
      document.title = '新規予約作成' + this.reversion.name + '顧客ID' +
            this.reversion.id || '新規予約作成' + this.reversion.memberId
    },

    async getFacility () {
      await this.$apollo.query({
        query: gql`${FACILITY_LIST}`
      }).then((data) => {
        const facName = data.data.facilityList.filter((item) => parseInt(item.id) === parseInt(this.facilityId))
        this.selectFacility = facName[0].name
        this.getRoomTypeName(facName[0].roomTypes)
      }).catch((error) => {
        console.error(error)
      })
    },
    getRoomTypeName (roomTypeList) {
      const roomTypeName = roomTypeList.find((item) => parseInt(item.id) === parseInt(this.roomTypeId))
      this.roomType = roomTypeName.name
    },

    getNightDay () {
      var timeDiff = Math.abs(moment(this.checkInDate) - moment(this.checkOutDate))
      this.numNight = Math.ceil(timeDiff / (1000 * 3600 * 24))
    },
    formatDate (value) {
      var d = moment(value).format('dddd')
      switch (d) {
        case 'Sunday':
          d = '日'
          break
        case 'Monday':
          d = '月'
          break
        case 'Tuesday':
          d = '火'
          break
        case 'Wednesday':
          d = '水'
          break
        case 'Thursday':
          d = '木'
          break
        case 'Friday':
          d = '金'
          break
        case 'Saturday':
          d = '土'
          break
      }
      if (moment(value) > moment(this.toDate)) {
        this.toDate = value
      }
      return moment(value).format('yyyy年MM月DD日 (' + d + ')')
    },
    showModal (value) {
      this.showDialog = value
      document.documentElement.classList.remove('modal-overflow')
    }
  }
}
</script>

<style scoped lang="scss">
.text-small{
  color: #000000 !important;
  font-weight: 600 !important;
}
h5{
  color: #444444 ;
  font-weight: 500 !important;
  font-size: 14px !important;
}
h3{
  font-size: 22px !important;
  font-weight: bold !important;
  color: #000000 !important;
}

.textdate{
  font-size: 10px ;
  font-weight: 500 ;
  color: #000000 ;
}
.text-size-normal{
  color: #000000 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.btn-add-parking {
  border: var(--text__primary) 2px solid;
  background-color: var(--bt__while) !important;
  color: var(--text__primary);
}

.text-title::v-deep label {
  font-size: 12px !important;
  font-weight: bold;
}
.mdi-calendar-month {
  color: #333 !important;
}
thead{
  tr{
    th{
      color: #666666 !important;
      font-size: 16px !important;
      font-weight: 600 !important;
    }
  }
}
::v-deep{
  .v-text-field__slot{
    input{
      color: #666666 !important;
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }
  .v-select__selections{
    .v-select__selection--comma{
      color: #666666 !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
  .text-title{
    input{
    color: #AAAAAA !important;
    font-weight: 500;
    }
  .v-label{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #AAAAAA !important;
    &--active{
      font-size: 10px !important;
      font-weight: 600 !important;
      color: #000000 !important;
    }
  }
}
  .text--black,
  .text--black{
fieldset{
  height: 46px !important;
}
.v-text-field__slot{
  input{
    margin-bottom: 15px !important;
  }
}
.v-text-field__details{
  margin-top: -15px !important;
}
  }
}
</style>
