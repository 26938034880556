export function getAddressString (address, options = { withPostalCode: false }) {
  if (!address) return ''
  return [
    options.noPostalCode ? address.postalCode : '',
    address.prefecture,
    address.locality,
    address.address1,
    address.address2
  ].filter(d => !!d).join(' ')
}
